import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "../resources/css/Resume.css";

const Resume: React.FC = () => {
  return (
    <div>
      <div className="header_hero_shape d-none d-lg-block"></div>
      <div className="mobile_version" style={{ marginTop: 120 }}>
        <div className="header">
          <h1>Manjeet Dahiya</h1>
          <div className="contact-info">
            <p>
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+61473399833"> +61 473 399 833</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:manjeet88@hotmail.com"> manjeet88@hotmail.com</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faLinkedin} />
              <a href="https://www.linkedin.com/in/manjeet-dahiya-2463b9a2/">
                {" "}
                LinkedIn
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="resume-container">
        <div className="sidebar">
          <div className="sidebar-section">
            <h2>EDUCATION</h2>
            <div className="sidebar-item">
              <h3>University of Canberra</h3>
              <p>
                Bachelor of Information Technology: Software Engineering and
                Information Systems | GPA: 6.04/7
              </p>
              <p className="date">June 2021</p>
            </div>
            <div className="sidebar-item">
              <h3>Navitas Professional</h3>
              <p>ACS Professional Year Program: Certificate IV in Business</p>
              <p className="date">August 2022</p>
            </div>
          </div>
          <div className="sidebar-section">
            <h2>SKILLS</h2>
            <ul>
              <li>Server Maintenance</li>
              <li>Troubleshooting</li>
              <li>Cloud Services Management (AWS)</li>
              <li>Help Desk Support</li>
              <li>React.js</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>Drupal</li>
              <li>WordPress</li>
              <li>Bootstrap</li>
              <li>GitHub</li>
              <li>MySQL</li>
              <li>Redmine</li>
              <li>Database Management</li>
              <li>Microsoft Office 365</li>
              <li>Dynamics 365</li>
            </ul>
          </div>
        </div>
        <div className="main-content">
          <div className="desktop_version">
            <div className="header">
              <h1>Manjeet Dahiya</h1>
              <div className="contact-info">
                <p>
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  <a href="tel:+61473399833">+61 473 399 833</a> |{" "}
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  <a href="mailto:manjeet88@hotmail.com">
                    manjeet88@hotmail.com
                  </a>{" "}
                  | <FontAwesomeIcon icon={faLinkedin} />{" "}
                  <a href="https://www.linkedin.com/in/manjeet-dahiya-2463b9a2/">
                    LinkedIn
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="section">
            <h2>PROFESSIONAL SUMMARY</h2>
            <p>
              As an IT professional, my experience and knowledge span project
              management, web development, help desk, system administration, and
              testing. I leverage this diverse skill set to oversee projects
              from inception to completion, ensuring meticulous attention to
              detail and delivering high-quality results. I thrive in leadership
              roles, adept at guiding cross-functional teams and developing
              dynamic websites and applications. Additionally, I specialise in
              establishing robust testing frameworks to optimise user
              experiences. With adaptability and strong multitasking abilities,
              I excel in both independent and collaborative work environments.
            </p>
          </div>
          <div className="section">
            <h2>EXPERIENCE</h2>
            <div className="job">
              <h3>Project Coordinator</h3>
              <p>OSKY | Fyshwick, ACT</p>
              <p className="date">02/2023 - Present</p>
              <ul>
                <li>
                  Manage and coordinate website and application development
                  projects, spanning both government and private sectors,
                  ensuring timely delivery, budget compliance, and alignment
                  with organisational objectives.
                </li>
                <li>
                  Manage and collaborate with a team of 15 developers, testers,
                  and system administrators to define project scope,
                  requirements, development, testing and timely delivery of
                  tasks.
                </li>
                <li>
                  Develop and maintain comprehensive project plans, schedules,
                  and resource allocation strategies to optimise workflow and
                  minimise risks.
                </li>
                <li>
                  Conduct regular project status meetings, providing updates to
                  stakeholders, addressing, and resolving concerns, and
                  facilitating effective decision-making processes.
                </li>
                <li>
                  Provide 1st and 2nd level help desk support for clients and
                  team members.
                </li>
                <li>
                  Assign incidents and requests to the correct support group.
                </li>
                <li>
                  Act as a single point of contact on incidents and problems
                  logged.
                </li>
                <li>
                  Perform appropriate diagnostics to initiate the problem
                  management workflow process.
                </li>
                <li>
                  Conduct performance, end-to-end, unit, and functional tests on
                  critical software applications, detecting bugs and resolving
                  performance bottlenecks.
                </li>
                <li>
                  Participate in system and user acceptance testing and quality
                  assurance checks ensuring that website or application meets
                  user requirements and standards reducing the number of
                  post-release defects.
                </li>
                <li>
                  Analyse and document test results to provide valuable insights
                  to the development team and improve overall software quality.
                </li>
                <li>
                  Use the Redmine Ticketing System to assign tickets ensuring
                  efficient tracking, prioritisation, and resolution of support
                  requests and incidents.
                </li>
                <li>
                  Provide initial basic and additional supplemental training to
                  team members regarding the utilisation of installed systems
                  and software as necessary.
                </li>
                <li>
                  Develop and implement standard operating procedures (SOPs) and
                  guidelines for ticket handling, escalation processes, and
                  service level agreements (SLAs).
                </li>
              </ul>
            </div>
            <div className="job">
              <h3>Web Developer</h3>
              <p>OSKY | Fyshwick, ACT</p>
              <p className="date">04/2022 - 02/2023</p>
              <ul>
                <li>
                  Write clean and efficient code using various front-end and
                  back-end programming languages such as HTML, JavaScript, and
                  XML to create websites according to client specifications and
                  wireframes generated by our business analyst and UX designer.
                </li>
                <li>
                  Build responsive and mobile-friendly user interfaces using
                  front-end frameworks like React.
                </li>
                <li>
                  Experience with content management systems such as Drupal and
                  WordPress.
                </li>
                <li>
                  Ensure cross-browser compatibility and optimise website
                  performance.
                </li>
                <li>
                  Integrate front-end components with back-end systems and APIs.
                </li>
                <li>
                  Communicate and effectively correspond to client emails and
                  organise weekly meetings with them.
                </li>
                <li>
                  Work closely with clients to provide constant maintenance of
                  clients' websites and solve any issues that may arise.
                </li>
                <li>
                  Perform debugging and troubleshooting to identify and resolve
                  issues in web applications.
                </li>
                <li>
                  Prepare reports, manuals and other documentation on the
                  status, operation, and maintenance of apps.
                </li>
              </ul>
            </div>
            <div className="job">
              <h3>Sales Professional</h3>
              <p>David Jones | City, ACT</p>
              <p className="date">10/2014 - 03/2022</p>
              <ul>
                <li>
                  Entering stock receipt invoices and adjusting inventory into
                  company software (AP21 and QlikView).
                </li>
                <li>Creating week-in-review reports of sales and budgets.</li>
                <li>
                  Experience in personally driving sales and delivering
                  exceptional customer service to lead by example, ensuring
                  achievement of key area targets and sales budgets.
                </li>
                <li>
                  Exceptional record of signing up potential clients with
                  American Express credit cards with instant approval.
                </li>
                <li>
                  Assisting customers with purchases and resolving customer
                  complaints and queries.
                </li>
                <li>
                  Actively encourage customers to sign up for the David Jones
                  Rewards program.
                </li>
                <li>
                  Assisting with training and onboarding of new team members.
                </li>
              </ul>
            </div>
          </div>
          <div className="section">
            <h2>CERTIFICATIONS</h2>
            <ul>
              <li>AWS Cloud Practitioner Essentials</li>
              <li>AWS DevOps</li>
              <li>AWS Fundamentals</li>
            </ul>
          </div>
          <div className="section">
            <h2>STRENGTHS</h2>
            <ul>
              <li>Team Leader</li>
              <li>Data manipulation</li>
              <li>Reporting</li>
              <li>Analytics</li>
              <li>Project Management</li>
              <li>Problem-solving</li>
              <li>Organisational Improvement</li>
              <li>Communication</li>
              <li>Attention to detail</li>
              <li>Teamwork</li>
              <li>Customer Service</li>
              <li>Critical thinking</li>
            </ul>
          </div>
          <div className="section">
            <h2>INTERESTS</h2>
            <ul>
              <li>Coding</li>
              <li>Gym Enthusiast</li>
              <li>Travelling</li>
            </ul>
          </div>
          <div className="section">
            <h2>AWARDS</h2>
            <ul>
              <li>Dean's Excellence Awards University of Canberra</li>
              <li>Semester 1 ▪ February 2020 – June 2020</li>
              <li>Semester 2 ▪ August 2020 – November 2020</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
