import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../..//resources/css/ContactForm.css";

const ContactForm: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://codewithmanjeetworker.manjeet88.workers.dev/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, message }),
        }
      );
      if (response.ok) {
        navigate("/thank-you", { state: { name, email, message } });
      } else {
        alert("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to save data");
    }
  };

  return (
    <div>
      <div className="new-timeline-contact-page">
        <h1 className="contact-word">Contact</h1>
      </div>
      <section className="hero align-items-center">
        <div className="container">
          <div className="hero-content custom-row align-items-center">
            <div className="intro col-lg-6">
              <h2>Get in Touch</h2>
              <p></p>
              <p>
                <i className="fas fa-user"></i> Name: Manjeet Dahiya
              </p>

              <p>
                <i className="fas fa-envelope"></i> Email:{" "}
                <a href="mailto:maneet88@hotmail.com">maneet88@hotmail.com</a>
              </p>
              <p>
                <i className="fas fa-phone"></i> Phone:{" "}
                <a href="tel:+61473399833">+61 473 399 833</a>
              </p>

              <p>
                <i className="fas fa-map-marker-alt"></i> Location: Canberra,
                Australia
              </p>
            </div>

            <div className="hero-image col-lg-6 col-md col-sm-7">
              <div
                className="header_hero_image mt-30-mobile wow fadeInRightBig"
                data-wow-duration="1.3s"
                data-wow-delay="1.8s"
              >
                <form onSubmit={handleSubmit} className="data-form">
                  <div>
                    <label htmlFor="name">Full Name:</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Email Address:</label>
                    <input
                      type="text"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your Email Address"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="message">Message:</label>

                    <textarea
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Message for me"
                      required
                      rows={5}
                      cols={50}
                    ></textarea>
                  </div>
                  <button className="hire-me-contact" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="footer">
        © 2024 All Rights Reserved by Manjeet Dahiya.
      </div>
    </div>
  );
};

export default ContactForm;
