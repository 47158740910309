import "../../resources/css/WorkExperience.css";

const WorkExperience = () => {
  return (
    <div>
      <div className="new-timeline2">
        <h1 className="contact-word">Experience</h1>
      </div>
      <div className="new-timeline">
        <div className="new-container new-left">
          <div className="new-opposite-content">
            <div className="new-content-opposite3">
              <h3>Project Coordinator</h3>
              <h5>OSKY | Fyshwick, ACT</h5>
              <p>02/2023 - Present</p>
            </div>
          </div>

          <div className="new-content-opposite">
            <ul>
              <li>
                Manage and coordinate website and application development
                projects, spanning both government and private sectors, ensuring
                timely delivery, budget compliance, and alignment with
                organisational objectives.
              </li>
              <li>
                Manage and collaborate with a team of 15 developers, testers,
                and system administrators to define project scope, requirements,
                development, testing and timely delivery of tasks.
              </li>
              <li>
                Develop and maintain comprehensive project plans, schedules, and
                resource allocation strategies to optimise workflow and minimise
                risks.
              </li>
              <li>
                Conduct regular project status meetings, providing updates to
                stakeholders, addressing, and resolving concerns, and
                facilitating effective decision-making processes.
              </li>
              <li>
                Provide 1st and 2nd level help desk support for clients and team
                members.
              </li>
              <li>
                Assign incidents and requests to the correct support group.
              </li>
              <li>
                Act as a single point of contact on incidents and problems
                logged.
              </li>
              <li>
                Perform appropriate diagnostics to initiate the problem
                management workflow process.
              </li>
              <li>
                Conduct performance, end-to-end, unit, and functional tests on
                critical software applications, detecting bugs and resolving
                performance bottlenecks.
              </li>
              <li>
                Participate in system and user acceptance testing and quality
                assurance checks ensuring that website or application meets user
                requirements and standards reducing the number of post-release
                defects.
              </li>
              <li>
                Analyse and document test results to provide valuable insights
                to the development team and improve overall software quality.
              </li>
              <li>
                Use the Redmine Ticketing System to assign tickets ensuring
                efficient tracking, prioritisation, and resolution of support
                requests and incidents.
              </li>
              <li>
                Provide initial basic and additional supplemental training to
                team members regarding the utilisation of installed systems and
                software as necessary.
              </li>
              <li>
                Develop and implement standard operating procedures (SOPs) and
                guidelines for ticket handling, escalation processes, and
                service level agreements (SLAs).
              </li>
            </ul>
          </div>
        </div>
        <div className="new-container new-right">
          <div className="new-opposite-content">
            <div className="new-content-opposite2">
              <h3>Web Developer</h3>
              <h5>OSKY | Fyshwick, ACT</h5>
              <p>04/2022 - 02/2023</p>
            </div>
          </div>
          <div className="new-content">
            <ul>
              <li>
                Write clean and efficient code using various front-end and
                back-end programming languages such as HTML, JavaScript, and XML
                to create websites according to client specifications and
                wireframes generated by our business analyst and UX designer.
              </li>
              <li>
                Build responsive and mobile-friendly user interfaces using
                front-end frameworks like React.
              </li>
              <li>
                Experience with content management systems such as Drupal and
                WordPress.
              </li>
              <li>
                Ensure cross-browser compatibility and optimise website
                performance.
              </li>
              <li>
                Integrate front-end components with back-end systems and APIs.
              </li>
              <li>
                Communicate and effectively correspond to client emails and
                organise weekly meetings with them.
              </li>
              <li>
                Work closely with clients to provide constant maintenance of
                clients' websites and solve any issues that may arise.
              </li>
              <li>
                Perform debugging and troubleshooting to identify and resolve
                issues in web applications.
              </li>
              <li>
                Prepare reports, manuals and other documentation on the status,
                operation, and maintenance of apps.
              </li>
            </ul>
          </div>
        </div>
        <div className="new-container new-left">
          <div className="new-opposite-content">
            <h3>Sales Professional</h3>
            <h5>David Jones | City, ACT</h5>
            <p>10/2014 - 03/2022</p>
          </div>
          <div className="new-content">
            <ul>
              <li>
                Entering stock receipt invoices and adjusting inventory into
                company software (AP21 and QlikView).
              </li>
              <li>Creating week-in-review reports of sales and budgets.</li>
              <li>
                Experience in personally driving sales and delivering
                exceptional customer service to lead by example, ensuring
                achievement of key area targets and sales budgets.
              </li>
              <li>
                Exceptional record of signing up potential clients with American
                Express credit cards with instant approval.
              </li>
              <li>
                Assisting customers with purchases and resolving customer
                complaints and queries.
              </li>
              <li>
                Actively encourage customers to sign up for the David Jones
                Rewards program.
              </li>
              <li>
                Assisting with training and onboarding of new team members.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
