import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../resources/css/ContactForm.css";
import heroImage from "../resources/images/Email_thank_you_page.png";

const ThankYouPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, email, message } = location.state || {
    name: "",
    email: "",
    message: "",
  };

  return (
    <div>
      <div
        className="hero-image-thankyou thank-you-email"
        data-wow-duration="1.3s"
        data-wow-delay="1.8s"
      >
        <div className="header_hero_shape d-none d-lg-block"></div>

        <img src={heroImage} alt="hero" />
      </div>

      <div className="thank-you-page">
        <h1>Thank you for contacting me!</h1>
        <p>I have received your message.</p>
        <p>I'll reach you out immediately!</p>
        <div style={{ textAlign: "left" }}>
          <p>
            <strong>Name:</strong> {name}
          </p>
          <p>
            <strong>Email Address:</strong> {email}
          </p>
          <p>
            <strong>Message:</strong> {message}
          </p>
        </div>

        <button style={{ textAlign: "center" }} onClick={() => navigate("/")}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
