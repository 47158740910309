import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ContactForm from "./pages/HomePage/ContactForm";
import ThankYouPage from "./pages/ThankYouPage";
import LeetCode from "./pages/LeetCode";
import Nav from "./Nav";
import TodoList from "./pages/TodoList";
import Resume from "./pages/ResumePage";
import Hero from "./pages/HomePage/Hero";
import HomePage from "./pages/HomePage";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Nav />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/" element={<Hero />} />
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/code" element={<LeetCode />} />
          <Route path="/todos" element={<TodoList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
