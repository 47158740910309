import React, { useEffect } from "react";
import Typed from "typed.js";
import "../../resources/css/Hero.css";
import heroImage from "../../resources/images/Manjeet_profile_pic.png";
// import heroImage from "../../resources/images/Hero_template_page.png";
import resume from "../../resources/files/Resume.pdf";

export default function Hero() {
  useEffect(() => {
    const options = {
      strings: ["Web Developer", "Project Coordinator"],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      startDelay: 1000,
      loop: true,
      showCursor: true,
      cursorChar: "|",
    };
    const typed = new Typed("#typed-element", options);

    // Cleanup function to destroy the typed instance on component unmount
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <section className="hero header_hero align-items-center">
        <div className="container">
          <div className="hero-content custom-row mt-25 align-items-center ">
            <div className="intro col-lg-6">
              <h2>Hello I'm</h2>
              <h1>Manjeet Dahiya</h1>
              {/* <h1>James Jo</h1> */}
              <p>
                I'm a <span className="typed" id="typed-element"></span>
                <span
                  className="typed-cursor typed-cursor--blink"
                  aria-hidden="true"
                >
                  |
                </span>
              </p>
              <p>
                Experienced IT professional adept at managing projects, web
                development, system administration, and testing, excelling in
                leadership and optimizing user experiences.
              </p>

              <a href={resume} download="Manjeet_Dahiya_CV.pdf">
                <button className="hire-me">Download CV</button>
              </a>

              {/* <a href={resume} download="Manjeet_Dahiya_Resume.pdf">
                <button className="hire-me">Download CV</button>
              </a> */}
            </div>

            <div className="hero-image col-lg-6 col-md col-sm-7 justify-content-center">
              <div
                className="header_hero_image mt-50 wow fadeInRightBig"
                data-wow-duration="1.3s"
                data-wow-delay="1.8s"
              >
                {/* <img src="../assests/manjeet.png" alt="hero" /> */}
                <img src={heroImage} alt="hero" />
              </div>
            </div>
          </div>
        </div>
        <div className="header_hero_shape d-none d-lg-block"></div>
      </section>
    </div>
  );
}
