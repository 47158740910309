import React from "react";
import Navbar from "../components/navbar";
import Todos from "../components/todos";
import "../resources/css/TodoList.css";

// Your component code here

const TodoList: React.FC = () => {
  return (
    <div className="container">
      <Navbar />
      <Todos />
    </div>
  );
};

export default TodoList;
