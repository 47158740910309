import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const LeetCode: React.FC = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <div className="header_hero_shape d-none d-lg-block"></div>

      <div className="new-timeline-contact-page" style={{ marginTop: 120 }}>
        <h1 className="contact-word">LeetCode</h1>
      </div>
      <section className="hero align-items-center">
        <div className="container">
          <div>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <h2 style={{ textAlign: "center", color: "black" }}>
                      Project 2
                    </h2>
                    <div>Coming Soon</div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <h2 style={{ textAlign: "center", color: "black" }}>
                      Project 1
                    </h2>
                    <div>Coming Soon</div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <h2 style={{ textAlign: "center", color: "black" }}>
                      Project 3
                    </h2>
                    <div>Coming Soon</div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <h2 style={{ textAlign: "center", color: "black" }}>
                      Project 4
                    </h2>
                    <div>Coming Soon</div>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LeetCode;
