import React from "react";
import Hero from "./HomePage/Hero";
import ContactForm from "./HomePage/ContactForm";
import WorkExperience from "./HomePage/WorkExperience";
const HomePage: React.FC = () => {
  return (
    <div>
      <div>
        <section id="home">
          <Hero />
        </section>
        <section id="work-experience">
          <WorkExperience />
        </section>
        <section id="contact-form">
          <ContactForm />
        </section>
      </div>
    </div>
  );
};

export default HomePage;
